export const AppConfig = {
    /**
     * Site page title
     * Eg: 'React Test'
     * */
    SITE_TITLE: 'Geeta Aviation',

    /**
     * Debug mode
     * */
    DEBUG: false,

    /**
     * App base url
     * Eg: 'http://domain.com/'
     * */
    BASE_URL: 'http://complain.geetaaviation.com/',

    /**
     * Api server version
     * Eg: '1.0'
     * */
    API_VERSION: '1.0',

    /**
     * Api server token code
     * Eg: 'zSU3WBuW5FnW5mTR4rTpRW'
     * */
    API_TOKEN: 'zSU3WBuW5FnW5mTR4rTpRW',

    /**
     * Api server base url
     * Eg: 'http://domain.com/'
     * */
    API_BASE_URL: 'http://79.143.191.56/',
    // API_BASE_URL: 'http://localhost/laratest/public/',

    /**
     * Api server base url's postfix
     * Eg: 'api/v1/test_api/'
     * */
    API_BASE_URL_POSTFIX: 'api/v1/complain/',
};
