import {createMuiTheme} from '@material-ui/core/styles';

const ThemeConfig = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    // palette: {
    //     primary: {
    //         main: '#420c06',
    //         contrastText: '#fff',
    //     },
    //     secondary: {
    //         main: '#04263f',
    //         contrastText: '#fff',
    //     },
    // },
});

export default ThemeConfig;