import React, {Component} from 'react';
import {object} from 'prop-types';
import './App.css';
import {connect} from "react-redux";
import ThemeConfig from "./config/theme";
import Main from "./components/Main";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {SnackbarProvider} from "notistack";

class App extends Component {
    static propTypes = {
        userReducer: object.isRequired
    };

    render() {
        return (
            <MuiThemeProvider theme={ThemeConfig}>
                <SnackbarProvider maxSnack={3}>
                    <div className="App">
                        <Main/>
                    </div>
                </SnackbarProvider>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps)(App);
