import {purgeUser} from "../../actions/user";
import {connect} from "react-redux";
import Header from "./header";

const mapStateToProps = state => ({
    ...state.userReducer
});

const mapDispatchToProps = dispatch => ({
    purgeUser: () => dispatch(purgeUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);