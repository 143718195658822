import {BrowserRouter as Router, Route, Switch, withRouter} from 'react-router-dom';
import React, {Component, Suspense, lazy} from 'react';
import './main.css';
import {withSnackbar} from 'notistack';
import * as PropTypes from "prop-types";
import Header from "./../Header";

const Error404 = lazy(() => import('./../Error/error404'));
const Home = lazy(() => import('./../Home'));
// const Login = lazy(() => import('./../Login'));
const StudentLogin = lazy(() => import('./../StudentLogin'));
const StudentPost = lazy(() => import('./../StudentPost'));
const StudentView = lazy(() => import('./../StudentView'));

class Main extends Component {
    static propTypes = {
        userReducer: PropTypes.object.isRequired,
        enqueueSnackbar: PropTypes.func.isRequired
    };

    // componentDidMount() {
    //     console.log({'main.js': this.props});
    // }

    render() {
        const {isLoggedIn} = this.props.userReducer;

        return (
            <Router>
                <div className="Main">
                    {isLoggedIn &&
                    <Header/>
                    }
                    <Suspense fallback={<div>Loading...</div>}>
                        {isLoggedIn ? (
                            <Switch>
                                <Route exact path="/" render={withRouter(Home)}/>
                                <Route exact path="/student-post" render={withRouter(StudentPost)}/>
                                <Route path="/student-view/:id" render={withRouter(StudentView)}/>
                                <Route path="/" render={withRouter(Error404)}/>
                            </Switch>
                        ) : (
                            <Switch>
                                {/*<Route exact path="/login" render={withRouter(Login)}/>*/}
                                <Route path="/" render={withRouter(StudentLogin)}/>
                            </Switch>
                        )}
                    </Suspense>
                </div>
            </Router>
        );
    }
}

export default withSnackbar(Main);