import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {AppConfig} from "../../config/app";
import './header.css';
import {Button} from "@material-ui/core";
import {Link} from 'react-router-dom'

class Header extends Component {
    render() {
        // const {user} = this.props;

        return (
            <div className="Header">
                <AppBar position="static">
                    <Toolbar>
                        <div className="site-title-wrapper">
                            <Button color="inherit" className="site-title" component={Link} to="/">{AppConfig.SITE_TITLE}</Button>
                        </div>
                        <Button color="inherit" component={Link} to="/student-post">Post</Button>
                        <Button color="inherit" onClick={this.props.purgeUser}>Logout{/* - {user.name}*/}</Button>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default Header;