import {handleActions} from 'redux-actions';

import actions from './../actions/user';

export const name = 'user';

const initialState = {
    isLoggedIn: false,
    user: {
        id: null,
        name: '',
        email: ''
    }
};

const reducerMap = {
    [actions.purgeUser]: (state) => {
        localStorage.removeItem('loggedInUser');
        return {
            ...state,
            isLoggedIn: false
        };
    },
    [actions.setUser]: (state, {payload}) => {
        return {
            ...state,
            isLoggedIn: true,
            user: {
                id: payload.id,
                name: payload.name,
                email: payload.email,
                profile: payload.profile,
                authorization: payload.authorization
            }
        };
    }
};

export default handleActions(reducerMap, initialState);
