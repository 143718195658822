import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import log from './log';

const middleware = [thunk];

/* Todo: remove while going production */
middleware.push(log);

export default applyMiddleware(...middleware);